$primary: #000e5d;
$danger: #ff4136;
$form-check-input-width: 1.5em;
$form-check-min-height: 2.5em;

$h1-font-size: 2rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1rem;
$h6-font-size: 0.85rem;

@import '../node_modules/bootstrap/scss/bootstrap';
