h1 {
    font-size: 1.5rem;
}

h2 {
    font-size: 1.25rem;
}

p {
    font-size: .875rem;
}