.Layout__wrapper {
  min-height: 100vh;
}

.Layout__page-header {
  display: flex;
  flex-direction: flex-start;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #ddd;
  width: 100%;
  position: fixed;
  height: 78px;
}

.Layout__save-button {
  height: auto;
}

.Layout__page-content {
  width: 100%;
  padding-top: 90px;
}
